//import "./styles.css";
import TableCell from "@mui/material/TableCell";
import { useState } from "react";
import { Button } from "@mui/material";
import "./Impo.scss";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Autocomplete
} from "@mui/material";

const data = [
  {
    id: 1001,
    label: "Action1",
  },
  {
    id: 1002,
    label: "Action2",
  }, 
];

export default function About() {
  const [selectedId, setSelectedId] = useState();

  const handleClick = (id) => {
    setSelectedId(id !== selectedId ? id : null);
  };
  return (
    <>
      <div className="card">
        - Item Info -
        <TextField
          id="filled-number"
          label="Description"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
        />
      </div>
      {data.map((val) => (
        <div>
          <TableCell>
            <Button
              onClick={() => handleClick(val.id)}
            >
              {val.label}
            </Button>
            {selectedId === val.id && (
              <div className="select-option">
                <ul id="action1" className="select-option-inner rounded">
                  <li>
                    <b>{val.label}</b>
                  </li>
                  <li>Buy More</li>
                  <li>Sell </li>
                  <li>Next Li Tag</li>
                  <li>Edit Detials</li>
                </ul>
              </div>
            )}
          </TableCell>
        </div>
      ))}
    </>
  );
}
